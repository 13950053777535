// global styles
import "./src/css/swiper-bundle.min.css"
import "./src/css/styles.css"

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://fast.wistia.com/assets/external/E-v1.js")
  }
}
